<template>
  <el-container class="list fill">
    <avue-crud ref="crud"
               style="display:none"
               :option="option"
               v-model="form"
               :page.sync="page"
               @row-save="rowSave"
               @row-update="rowUpdate"
               @row-del="rowDel"
               v-loading="loading"
               v-bind="$loadingParams"
               :before-open="beforeOpen"
               :data="data"
               @on-load="onLoad">
      <template slot="optionsForm"
                slot-scope="{}">
        <monaco-editor v-model="form.options"
                       language="javascript"
                       height="300"></monaco-editor>
      </template>
    </avue-crud>
    <el-aside style="width:220px;">
      <tree-menu type="fill"
                 @node-click="handleNodeClick"></tree-menu>
    </el-aside>
    <el-container>
      <el-header class="content__header">
        <div class="content__box content__nav">
          <div class="content__add"
               @click="$refs.crud.rowAdd()">
            <img :src="`${$router.options.base}img/fill.png`"
                 height="40px"
                 alt="">
            <div>
              <p>新建填报</p>
              <span>可用数据上报和收集</span>
            </div>
          </div>
          <div class="content__page">

            <div class="list-search">
              <el-input v-model="search.name"
                        size="small"
                        @keyup.enter.native="onLoad"
                        placeholder="请输入名称">
                <i slot="suffix"
                   @click="onLoad"
                   class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
            <el-pagination v-if="page.total>0"
                           layout="total, prev, pager, next"
                           background
                           size="small"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :page-size="page.pageSize"
                           :current-page.sync="page.currentPage"
                           :total="page.total">
            </el-pagination>
          </div>
        </div>
      </el-header>
      <el-main class="content"
               v-loading="loading"
               v-bind="$loadingParams">
        <div class="content__box">
          <template v-if="data.length>0">
            <div class="content__item"
                 @mouseenter="item._menu=true"
                 @mouseleave="item._menu=false"
                 @click="select(item,index)"
                 v-for="(item,index) in data"
                 :key="index">
              <div class="content__main">
                <span class="content__name">{{item.name}}</span>
                <span class="content__size">{{ item.type }}</span>
                <span class="content__time">{{ item.time }}</span>
              </div>

              <div class="content__menu">
                <div class="content__start">
                  <el-tooltip content="填报">
                    <div class="content__btn"
                         @click.stop="handleFill(item,index)">
                      <i class="el-icon-s-order"></i>
                    </div>
                  </el-tooltip>
                  <div class="content__btn"
                       @click.stop="handleEdit(item,index)">
                    <i class="el-icon-edit-outline"></i>
                  </div>
                  <div class="content__btn"
                       @click.stop="rowDel(item,index)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <el-empty v-else
                    class="content__empty"
                    description="暂无数据">
            <template #image>
              <svg-icon icon-class="empty" />
            </template>
          </el-empty>
        </div>
      </el-main>
      <el-dialog :title="`【${fillObject.item.name}】数据填报`"
                 class="avue-dialog"
                 :visible.sync="fillObject.show"
                 width="70%">
        <avue-crud :data="fillObject.data"
                   :page.sync="fillObject.page"
                   v-loading="loading"
                   v-bind="$loadingParams"
                   @on-load="fillOnLoad"
                   @row-save="fillRowSave"
                   @row-update="fillRowUpdate"
                   @row-del="fillRowDel"
                   :option="fillObject.option"></avue-crud>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
import { getList, getObj, addObj, delObj, updateObj } from '@avue/avue-data/api/fill'
import { getList as getFillList, addObj as addFillObj, delObj as delFillObj, updateObj as updateFillObj } from '@avue/avue-data/api/filldata'
import { url } from '@avue/avue-data/config';
import MonacoEditor from '@avue/avue-data/page/components/monaco-editor'
import treeMenu from '@avue/avue-data/page/components/tree'
import dayjs from 'dayjs'
export default {
  props: {
    menu: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MonacoEditor,
    treeMenu
  },
  data () {
    const safe = this;
    return {
      treeId: '',
      search: {},
      url,
      loading: false,
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      data: [],
      option: {
        dialogWidth: '600',
        dialogMenuPosition: 'center',
        height: 'auto',
        calcHeight: 330,
        header: false,
        index: true,
        align: 'center',
        labelWidth: 100,
        headerAlign: 'center',
        column: [
          {
            label: '填报名称',
            prop: 'name',
            rules: [{
              required: true,
              message: "请输入填报名称",
              trigger: "blur"
            }]
          },
          {
            label: '分类',
            prop: 'type',
            type: 'select',
            dicUrl: safe.$website.url + '/category/list?categoryModule=fill&current=1&size=99',
            props: {
              label: 'categoryKey',
              value: 'categoryValue'
            }
          },
          {
            label: '采集表格配置',
            prop: 'options',
            span: 24,
            rules: [{
              required: true,
              message: "请输入采集表格配置",
              trigger: "blur"
            }]
          },
          {
            label: '创建时间',
            addDisplay: false,
            prop: 'time',
            disabled: true,
            span: 24
          }
        ]
      },
      fillObject: {
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        item: {},
        show: false,
        option: {},
        data: []
      }
    }
  },
  methods: {
    handleNodeClick (data) {
      this.treeId = data.categoryValue;
      this.onLoad()
    },
    select (item) {
      this.$emit('submit', item.assetsUrl)
    },
    vaildData (id) {
      return [0, 1, 2].includes(id)
    },
    beforeOpen (done, type) {
      if (type == 'edit') {
        getObj(this.form.id).then(res => {
          const data = res.data.data;
          this.form = data
          done()
        })
      } else {
        this.form.type = this.treeId
        done()
      }
    },
    rowDel (row, index) {
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delObj(row.id).then(() => {
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    rowUpdate (row, index, done, loading) {
      if (this.vaildData(index) && this.$website.isDemo) {
        done();
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      updateObj(row).then(() => {
        done();
        this.$message.success('修改成功');
        this.onLoad()
      }).catch(err => {
        loading()
      })
    },
    handleFill (row, index) {
      getObj(row.id).then(res => {
        const data = res.data.data;
        this.fillObject = {
          item: data,
          page: {
            currentPage: 1
          },
          show: true,
          option: Object.assign({
            index: true,
          }, JSON.parse(data.options))
        }

      })
    },
    fillOnLoad () {
      this.loading = true
      getFillList({
        templateId: this.fillObject.item.id,
        current: this.fillObject.page.currentPage,
        size: this.fillObject.page.pageSize,
      }).then(res => {
        this.loading = false
        const data = res.data.data;
        let records = data.records;
        records = records.map(ele => {
          try {
            let object = JSON.parse(ele.data);
            object.id = ele.id
            return object
          } catch {
            return ele
          }
        })
        this.fillObject.data = records
        this.fillObject.page.total = data.total;
      })
    },
    fillRowSave (row, done, loading) {
      let data = {
        templateId: this.fillObject.item.id,
        data: JSON.stringify(row)
      }
      addFillObj(data).then(() => {
        this.$message.success('新增成功');
        this.fillOnLoad()
        done()
      }).catch(err => {
        loading()
      })

    },
    fillRowUpdate (row, index, done, loading) {
      let data = {
        id: row.id,
        templateId: this.fillObject.item.id,
        data: JSON.stringify(row)
      }
      updateFillObj(data).then(() => {
        this.$message.success('修改成功');
        this.fillOnLoad()
        done()
      }).catch(err => {
        loading()
      })
    },
    fillRowDel (row, index, done) {
      delFillObj(row.id).then(() => {
        this.$message.success('删除成功');
        this.fillOnLoad()
        done()
      })
    },
    handleEdit (row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    rowSave (row, done, loading) {
      row.time = dayjs().format('YYYY-MM-DD HH:mm:ss');
      addObj(row).then(() => {
        this.$message.success('新增成功');
        this.onLoad()
        done();
      }).catch(err => {
        loading()
      })
    },

    handleCurrentChange (val) {
      this.page.currentPage = val;
      this.onLoad();
    },
    handleSizeChange (val) {
      this.page.pageSize = val;
      this.onLoad();
    },
    onLoad () {
      this.loading = true
      let params = {}
      if (this.treeId) params.type = this.treeId;
      getList(Object.assign({
        assetsName: this.search.name,
        current: this.page.currentPage,
        size: this.page.pageSize,
      }, params)).then(res => {
        this.loading = false
        const data = res.data.data;
        let records = data.records
        records.forEach(ele => ele._menu = false);
        this.page.total = data.total;
        this.data = records;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fill {
  .content {
    &__info {
      height: auto;
    }
    &__item {
      margin-right: 0;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }
    &__logo {
      margin-right: 10px;
      img {
        object-fit: cover;
        width: 40px !important;
        height: 40px !important;
      }
    }
    &__main {
      flex: 1;
      justify-content: flex-start;
    }
    &__menu {
      width: 130px !important;
      margin-right: 10px;
      position: relative;
      width: 100px;
      height: inherit;
      right: 0;
      display: inline-block;
    }
    &__name {
      width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__size {
      min-width: 80px;
      margin-right: 100px;
    }
    &__type {
      min-width: 80px;
      margin-right: 100px;
    }
    &__time {
    }
  }
}
</style>